/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    blockquote: "blockquote",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Como uso mucho las carpetas de “Mis Documentos”, “Mi música”, “Mis Imágenes”… de mi partición windows, queria tener enlaces a estas carpetas en mi escritorio. Hacerlo es muy simple, utilizo un FolderView del directorio $HOME/Desktop para poner los iconos. En esta carpeta con el boton derecho creamos un link a un lugar y en este ponemos el nombre y la dirección. Hasta ahí sencillo pero ¿y si quiero que el enlace no tenga ese icono tan feo, sino que tenga el que yo quiero? Pues abrimos el enlace con KWrite y en “Icon=” podremos poner la dirección del icono que queremos. Lo siguiente que quiero hacer es sacar los iconos de windows para usarlos en estas carpetas. Para esto tenemos un programa muy sencillo de usar, extrae los iconos de dentro de archivos exe o dll, es un programa muy simple que tendremos que compilar nosotros mismos. Primero lo guardamos de esta página ", React.createElement(_components.a, {
    href: "http://usuarios.lycos.es/haberweb/exe2ico.c"
  }, "exe2ico.c"), " Nos situamos con la consola en la carpeta donde lo hemos guardado y lo compilamos."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">$ gcc exe2ico.c -o exe2ico</code>"
    }
  })), "\n"), "\n", React.createElement(_components.p, null, "Luego lo ejecutamos para que nos extraiga los iconos de shell32.dll"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">$ ./exe2ico /media/windows/WINDOWS/system32/shell32.dll</code>"
    }
  })), "\n"), "\n", React.createElement(_components.p, null, "Con todos los iconos que tenemos los metemos en una carpeta que yo he llamado windowsico y lo copiamos a la carpeta /usr/share/pixmaps que es donde se guardan los iconos, esto tendremos que hacerlo como root. Despues solo tendremos que copiar la ruta del icono que queramos utilizar y pegarla en el archivo .desktop con he dicho antes."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
